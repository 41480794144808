<template>
  <v-content class="core-content">
    <v-container fluid>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-container>
    
    <gavcom-core-footer />
    
  </v-content>
</template>

<script>
export default {
  name: "GavcomCoreView",

  components: {
    GavcomCoreFooter: () => import("@/layouts/gavcom/core/Footer")
  },

};
</script>

<style>
  .no-padding{
    padding: 0 !important;
  }
</style>